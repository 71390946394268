import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getSrc } from 'gatsby-plugin-image';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import BaseTeamCard from '../../components/shapes/Cards/TeamCards/HorizontalTeamCard';

const emptyImage =
  'https://res.cloudinary.com/appsolut365/image/upload/v1659269337/appsolut365/webportal/default/avatar_person_w6hmcg.png';

function TeamCard({ person }) {
  const { title, image, information, description } = person;

  const hasImage = !_.isEmpty(image);
  let imageSrc = emptyImage;
  if (hasImage) imageSrc = getSrc(image.gatsbyImageData);

  const positionProps = {
    color: 'secondary',
    label: information,
  };

  return (
    <StyledBox mt={3}>
      <BaseTeamCard
        image={imageSrc}
        name={title}
        position={positionProps}
        description={description}
      />
    </StyledBox>
  );
}

TeamCard.propTypes = {
  person: PropTypes.instanceOf(Object).isRequired,
};

export default TeamCard;

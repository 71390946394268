import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

// appsolut base components
import StyledBox from '../StyledBox';
import StyledTypography from '../StyledTypography';

function StyledProductPrice({ price }) {
  const hasPrice = !_.isEmpty(price);
  const hasAlias = hasPrice && price.alias;
  const priceValue = hasPrice ? price.price : '--';
  const isStartsFrom = hasPrice && price.ismin ? true : false;
  const startsFrom = 'ab';

  return (
    <StyledBox>
      {hasAlias && (
        <StyledTypography display="inline" variant="h4">
          {price.alias}
        </StyledTypography>
      )}
      {!hasAlias && (
        <Fragment>
          {hasPrice && isStartsFrom && (
            <StyledTypography display="inline" variant="body2" mr={1}>
              {startsFrom}
            </StyledTypography>
          )}
          <StyledTypography display="inline" variant="h3">
            {priceValue}
          </StyledTypography>
        </Fragment>
      )}
    </StyledBox>
  );
}

StyledProductPrice.propTypes = {
  price: PropTypes.shape({
    price: PropTypes.string.isRequired,
    ismin: PropTypes.bool,
    isspecial: PropTypes.bool,
    specialprice: PropTypes.string,
    info: PropTypes.string,
  }).isRequired,
};

export default StyledProductPrice;

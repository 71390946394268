import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import StyledHtmlViewer from '../../components/controls/StyledHtmlViewer';

function ImageCredit({ content }) {
  return (
    <StyledBox component="section">
      <Container>
        <Grid container>
          {content && (
            <Grid item xs={12} lg={9} mx="auto">
              <StyledHtmlViewer>
                <StyledTypography
                  component="div"
                  variant="caption"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
              </StyledHtmlViewer>
            </Grid>
          )}
        </Grid>
      </Container>
    </StyledBox>
  );
}

ImageCredit.propTypes = {
  content: PropTypes.string,
};

export default ImageCredit;

//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

// Otis Kit PRO components
import StyledBox from '../../../../controls/StyledBox';
import StyledTypography from '../../../../controls/StyledTypography';
import StyledHtmlViewer from '../../../../controls/StyledHtmlViewer';

function HorizontalTeamCard({ image, name, position, description }) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={3} sx={{ mt: 0 }}>
          <StyledBox width="100%" pt={2} pb={1} px={2}>
            <StyledBox
              component="img"
              src={image}
              alt={name}
              width="100%"
              borderRadius="md"
              shadow="lg"
            />
          </StyledBox>
        </Grid>
        <Grid item xs={9} sx={{ my: 'auto' }}>
          <StyledBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <StyledTypography variant="h5">{name}</StyledTypography>
            <StyledTypography variant="body2" color={position.color} mb={1}>
              {position.label}
            </StyledTypography>
            <StyledHtmlViewer>
              <StyledTypography
                component="div"
                variant="body2"
                color="text"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </StyledHtmlViewer>
          </StyledBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  position: PropTypes.shape({
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
    label: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
};

export default HorizontalTeamCard;

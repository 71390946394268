import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import _ from 'lodash';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Card from '@mui/material/Card';

// custom components
import { useSettings } from '../../hooks/SettingsHook';
import { Layout } from '../../components/layout/CatalogDefault';
import StyledBox from '../../components/controls/StyledBox';
import Content from '../../custom/segments/Content';
import ImageCredit from '../../custom/segments/ImageCredit';
import ProductTags from '../../custom/segments/ProductTags';
import ProductBooking from '../../custom/segments/ProductBooking';
import ProductVariants from '../../custom/segments/ProductVariants';
import Downloads from '../../custom/segments/Downloads';
import ContactPersons from '../../custom/segments/Contacts';
import Address from '../../custom/segments/Address';
import Gallery from '../../custom/segments/Gallery';
import ProductsRelated from '../../custom/segments/ProductsRelated';
import Newsletter from '../../custom/segments/NewsletterSimple';
import { formatPrice } from '../../utils/format-price';
import * as nodesUtils from '../../utils/nodes';
import * as config from '../../../config/site';

export const query = graphql`
  query ($id: String) {
    product: datoCmsItem(id: { eq: $id }) {
      id
      originalId
      title
      alias
      producttype
      coverHeader
      booking
      featured
      weight
      teaser
      content
      footer
      imageCredit
      price
      ismin
      priceAlias
      coverImage {
        gatsbyImageData(width: 1600, layout: FULL_WIDTH)
        alt
        basename
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, width: 800, aspectRatio: 1.3)
        alt
        basename
      }
      prices {
        ...PriceBlockCard
      }
      catalogs {
        ...CatalogCard
      }
      topics {
        ...TopicCard
      }
      destinations {
        ...DestinationCard
      }
      triptypes {
        ...TriptypeCard
      }
      venues {
        ...VenueWithImageCard
      }
      patches {
        ...PatchCard
      }
      qualities {
        ...QualityCard
      }
      services {
        ...ServiceCard
      }
      properties {
        ...PropertyCard
      }
      related {
        ...ProductWithImageCard
      }
      contacts {
        ...PersonBlockWithImageCard
      }
      gallery {
        alt
        basename
        fixed(width: 250) {
          src
        }
        gatsbyImageData(layout: CONSTRAINED, width: 1200, aspectRatio: 1.3)
      }
      downloads {
        title
        basename
        url
        customData
        size
        format
        filename
        alt
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allcatalogs: allDatoCmsCatalog {
      edges {
        node {
          id
          title
          originalId
        }
      }
    }
    siteSetting: datoCmsSiteSetting {
      catalog {
        id
        title
        originalId
      }
      catalogAlt {
        id
        title
        originalId
      }
    }
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

export default function Product({ data, pageContext }) {
  // context
  const location = useLocation();
  const { saveSettings } = useSettings() || {};
  // parameter management
  const { product, allcatalogs, siteSetting, site } = data;
  const { id, catalogType, catalog } = pageContext;
  const { originalId, booking, featured, weight } = product;
  const { price, ismin, priceAlias } = product;
  const { prices, related, contacts, downloads } = product;
  const { topics, destinations, triptypes, venues } = product;
  const { patches, qualities, services, properties } = product;

  const pageHeader = nodesUtils.getLabelFromProductType(product.producttype);
  const productHeader = product && product.alias ? product.alias : product.title;
  const coverHeader = product && product.coverHeader ? product.coverHeader : productHeader;
  const allCatalogs = allcatalogs ? allcatalogs.edges : [];

  const showNewsletter = config.segments.newsletter.enabled;

  const currencyCode = config.catalog.currency.code || 'EUR';
  const productPriceFormated = formatPrice(currencyCode, price);
  const productPrice = {
    price: productPriceFormated,
    ismin: ismin,
    alias: priceAlias,
  };
  const bookingBtn = { label: 'Buchung Anfragen' };

  const hasVariants = prices && prices.length > 0;
  const initialVariant = hasVariants ? prices[0] : {};

  const galleryImages = nodesUtils.getGalleryImages(product.gallery);
  const pageParams = {
    title: coverHeader,
    cover: { ...product.coverImage },
  };
  const catalogSettings = nodesUtils.getSiteCatalogs(siteSetting, allCatalogs);

  // state
  const [variant, setVariant] = useState({ ...initialVariant });
  const [quantity, setQuantity] = useState(1);
  const [available] = useState(true);

  // event handler
  const handleVariantChange = (event) => {
    const value = event.target.value;

    if (prices && prices.length > 0) {
      const selectedVariant = prices.find((variant) => variant.id === value);
      setVariant({ ...selectedVariant });
    }
  };

  // prepare category nodes
  let venueNodes = [];
  if (venues && venues.length > 0) {
    venues.forEach((node) => {
      const venueHeader = node && node.alias ? node.alias : node.title;
      const addressParams = {
        title: venueHeader,
        contact: node.contact,
        street: node.street,
        street2: node.street2,
        country: node.country,
        zipCode: node.zipCode,
        ort: node.ort,
        arrival: node.arrival,
        image: { ...node.image },
      };
      venueNodes.push({ ...addressParams });
    });
  }

  // prepare category nodes
  let categoryNodes = [];
  if (topics && topics.length > 0) {
    topics.forEach((node) => {
      const segmentType = config.catalog.topic.querykey;
      const segmentName = node && node.alias ? node.alias : node.title;
      const segmentKey = node && node.originalId ? node.originalId : '';
      const searchPath = nodesUtils.getSearchPathFromSegment(
        segmentType,
        segmentKey,
        catalogType,
        siteSetting,
        allCatalogs
      );
      categoryNodes.push({ id: node.id, label: segmentName, path: searchPath });
    });
  }
  if (destinations && destinations.length > 0) {
    destinations.forEach((node) => {
      const segmentType = config.catalog.destination.querykey;
      const segmentName = node && node.alias ? node.alias : node.title;
      const segmentKey = node && node.originalId ? node.originalId : '';
      const searchPath = nodesUtils.getSearchPathFromSegment(
        segmentType,
        segmentKey,
        catalogType,
        siteSetting,
        allCatalogs
      );
      categoryNodes.push({ id: node.id, label: segmentName, path: searchPath });
    });
  }
  if (triptypes && triptypes.length > 0) {
    triptypes.forEach((node) => {
      const segmentType = config.catalog.triptype.querykey;
      const segmentName = node && node.alias ? node.alias : node.title;
      const segmentKey = node && node.originalId ? node.originalId : '';
      const searchPath = nodesUtils.getSearchPathFromSegment(
        segmentType,
        segmentKey,
        catalogType,
        siteSetting,
        allCatalogs
      );
      categoryNodes.push({ id: node.id, label: segmentName, path: searchPath });
    });
  }
  if (venues && venues.length > 0) {
    venues.forEach((node) => {
      const segmentType = config.catalog.venue.querykey;
      const segmentName = node && node.alias ? node.alias : node.title;
      const segmentKey = node && node.originalId ? node.originalId : '';
      const searchPath = nodesUtils.getSearchPathFromSegment(
        segmentType,
        segmentKey,
        catalogType,
        siteSetting,
        allCatalogs
      );
      categoryNodes.push({ id: node.id, label: segmentName, path: searchPath });
    });
  }

  // prepare tag nodes
  let tagNodes = [];
  if (patches && patches.length > 0) {
    patches.forEach((node) => {
      const segmentType = config.catalog.patch.querykey;
      const segmentName = node && node.alias ? node.alias : node.title;
      const segmentKey = node && node.originalId ? node.originalId : '';
      const segmentIcon = node && node.icon ? node.icon : '';
      const segmentColor = node && node.color ? node.color : '';
      const searchPath = nodesUtils.getSearchPathFromSegment(
        segmentType,
        segmentKey,
        catalogType,
        siteSetting,
        allCatalogs
      );
      tagNodes.push({
        id: node.id,
        label: segmentName,
        path: searchPath,
        icon: segmentIcon,
        color: segmentColor,
      });
    });
  }
  if (qualities && qualities.length > 0) {
    qualities.forEach((node) => {
      const segmentType = config.catalog.quality.querykey;
      const segmentName = node && node.alias ? node.alias : node.title;
      const segmentKey = node && node.originalId ? node.originalId : '';
      const segmentIcon = node && node.icon ? node.icon : '';
      const segmentColor = node && node.color ? node.color : '';
      const searchPath = nodesUtils.getSearchPathFromSegment(
        segmentType,
        segmentKey,
        catalogType,
        siteSetting,
        allCatalogs
      );
      tagNodes.push({
        id: node.id,
        label: segmentName,
        path: searchPath,
        icon: segmentIcon,
        color: segmentColor,
      });
    });
  }
  if (services && services.length > 0) {
    services.forEach((node) => {
      const segmentType = config.catalog.service.querykey;
      const segmentName = node && node.alias ? node.alias : node.title;
      const segmentKey = node && node.originalId ? node.originalId : '';
      const segmentIcon = node && node.icon ? node.icon : '';
      const segmentColor = node && node.color ? node.color : '';
      const searchPath = nodesUtils.getSearchPathFromSegment(
        segmentType,
        segmentKey,
        catalogType,
        siteSetting,
        allCatalogs
      );
      tagNodes.push({
        id: node.id,
        label: segmentName,
        path: searchPath,
        icon: segmentIcon,
        color: segmentColor,
      });
    });
  }
  if (properties && properties.length > 0) {
    properties.forEach((node) => {
      const segmentType = config.catalog.property.querykey;
      const segmentName = node && node.alias ? node.alias : node.title;
      const segmentKey = node && node.originalId ? node.originalId : '';
      const segmentIcon = node && node.icon ? node.icon : '';
      const segmentColor = node && node.color ? node.color : '';
      const searchPath = nodesUtils.getSearchPathFromSegment(
        segmentType,
        segmentKey,
        catalogType,
        siteSetting,
        allCatalogs
      );
      tagNodes.push({
        id: node.id,
        label: segmentName,
        path: searchPath,
        icon: segmentIcon,
        color: segmentColor,
      });
    });
  }

  useEffect(() => {
    const backlink = location.pathname;
    const selectedProduct = {
      id: product.id,
      code: product.booking,
      key: product.originalId,
      title: product.title,
      alias: product.alias,
      producttype: product.producttype,
      coverHeader: product.coverHeader,
      featured: product.featured,
      weight: product.weight,
      teaser: product.teaser,
      content: product.content,
      footer: product.footer,
      imageCredit: product.imageCredit,
      price: product.price,
      ismin: product.ismin,
    };
    saveSettings({ caller: { booking: backlink }, product: { ...selectedProduct } });
  }, [product, location]);

  return (
    <Layout catalogType={catalogType} catalogId={catalog} pageParams={pageParams}>
      <HelmetDatoCms seo={product.seo} favicon={site.favicon} />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 7 },
          mt: -8,
          mb: 1,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <StyledBox pt={{ xs: 3, md: 6 }}>
          <Content header={pageHeader} title={productHeader} featured={featured} />
        </StyledBox>
        <ProductTags type={'button'} tags={tagNodes} color={'info'} withLink />
        <StyledBox pb={{ xs: 2, md: 3 }}>
          <Content teaser={product.teaser} body={product.content} />
        </StyledBox>
        <ProductTags header={'Kategorien'} type={'badge'} tags={categoryNodes} withLink />
        <ProductBooking
          header={'Preis'}
          price={productPrice}
          bookingCode={booking}
          productKey={originalId}
          actionParams={bookingBtn}
        />
        <ProductVariants product={product} variants={prices} />
        <StyledBox pt={{ xs: 1, md: 2 }}>
          <Content footer={product.footer} />
        </StyledBox>
        <StyledBox pt={{ xs: 1, md: 2 }}>
          <ImageCredit content={product.imageCredit} />
        </StyledBox>
        <Downloads header={'Downloads'} downloads={downloads} />
        {venueNodes.map((address) => (
          <Address address={address} />
        ))}
        <ContactPersons header={'Kontaktpersonen'} contacts={contacts} />
        <Gallery header={'Bildergalerie'} images={galleryImages} />
        <ProductsRelated
          products={related}
          header={'Das könnte ebenfalls interessant sein'}
          catalogType={catalogType}
        />
        {showNewsletter && <Newsletter />}

        {/* <div>
          <div>
            <div>
              <h2>
                <span>{priceFormatted}</span>
              </h2>
              <fieldset>
                {hasVariants && (
                  <div key={id}>
                    <select aria-label="Variants" onChange={(event) => handleVariantChange(event)}>
                      {prices.map(({ id, title }) => (
                        <option value={id} key={id}>
                          {title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
              </fieldset>
            </div>
          </div>
        </div> */}
      </Card>
    </Layout>
  );
}

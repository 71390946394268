//============================================================
// appsolut Theme VIENNA - v1.0.0
//============================================================
//
// Based on Otis Kit PRO - v2.0.1 from www.creative-tim.com
//
//============================================================

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Link } from 'gatsby-theme-material-ui';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import { yellow } from '@mui/material/colors';
//import FeaturedIcon from '@mui/icons-material/Bookmark';
//import FeaturedIcon from '@mui/icons-material/Star';
import FeaturedIcon from '@mui/icons-material/Spa';

// Otis Kit PRO components
import StyledBox from '../../../../controls/StyledBox';
import StyledTypography from '../../../../controls/StyledTypography';
import StyledProductPrice from '../../../../controls/StyledProductPrice';
import StyledButton from '../../../../controls/StyledButton';

function PriceBookingCard({
  image,
  title,
  featured,
  featuredLabel,
  description,
  price,
  categories,
  action,
}) {
  // TODO: make action optional >> Links

  const hasAction = !_.isEmpty(action);

  return (
    <Card>
      <StyledBox
        component={Link}
        to={action.route}
        target={action.type === 'external' ? '_blank' : undefined}
        rel={action.type === 'external' ? 'noreferrer' : undefined}
        position="relative"
        borderRadius="lg"
        mx={2}
        mt={-3}
      >
        {featured && (
          <Tooltip title={featuredLabel}>
            <StyledBox width="100%" position="relative" zIndex={2}>
              <FeaturedIcon
                fontSize="large"
                sx={{ color: yellow[500], position: 'absolute', top: '0.8rem', right: '1.2rem' }}
              />
            </StyledBox>
          </Tooltip>
        )}
        <StyledBox
          component="img"
          src={image}
          alt={title}
          borderRadius="lg"
          shadow="md"
          width="100%"
          position="relative"
          zIndex={1}
        />
        <StyledBox
          borderRadius="lg"
          shadow="md"
          width="100%"
          height="100%"
          position="absolute"
          left={0}
          top={0}
          sx={{
            backgroundImage: `url(${image})`,
            transform: 'scale(0.94)',
            filter: 'blur(12px)',
            backgroundSize: 'cover',
          }}
        />
      </StyledBox>
      <StyledBox p={3} mt={-2}>
        {categories.length > 0 && (
          <StyledTypography
            display="block"
            variant="button"
            color="text"
            fontWeight="regular"
            mb={0.75}
          >
            {categories.map((category, index) => (
              <Fragment>
                {index < categories.length - 1 ? (
                  <Fragment key={category}>{category}&nbsp;&bull;&nbsp;</Fragment>
                ) : (
                  <Fragment key={category}>{category}</Fragment>
                )}
              </Fragment>
            ))}
          </StyledTypography>
        )}
        <StyledTypography
          display="inline"
          variant="h5"
          fontWeight="bold"
          component={Link}
          to={action.route}
          target={action.type === 'external' ? '_blank' : undefined}
          rel={action.type === 'external' ? 'noreferrer' : undefined}
        >
          {title}
        </StyledTypography>
        <StyledBox mt={1} mb={3}>
          <StyledTypography variant="body2" component="p" color="text">
            {description}
          </StyledTypography>
        </StyledBox>
        <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-end">
          <StyledProductPrice price={price} />
          {hasAction && (
            <StyledButton
              component={Link}
              to={action.route}
              target={action.type === 'external' ? '_blank' : undefined}
              rel={action.type === 'external' ? 'noreferrer' : undefined}
              variant="outlined"
              size="small"
              color={action.color ? action.color : 'dark'}
            >
              {action.label}
            </StyledButton>
          )}
        </Stack>
      </StyledBox>
    </Card>
  );
}

// Setting default props for the PriceBookingCard
PriceBookingCard.defaultProps = {
  featured: false,
  featuredLabel: 'Für Sie ausgewählt',
  categories: [],
};

// Typechecking props for the PriceBookingCard
PriceBookingCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  featured: PropTypes.bool,
  featuredLabel: PropTypes.string,
  description: PropTypes.string.isRequired,
  categories: PropTypes.instanceOf(Array),
  price: PropTypes.instanceOf(Object),
  action: PropTypes.shape({
    type: PropTypes.oneOf(['external', 'internal']).isRequired,
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      'primary',
      'secondary',
      'info',
      'success',
      'warning',
      'error',
      'dark',
      'light',
    ]),
    label: PropTypes.string.isRequired,
  }),
};

export default PriceBookingCard;

import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import _ from 'lodash';
import * as config from '../../../config/site';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledButton from '../../components/controls/StyledButton';

function BookingButton({ code, productKey, params }) {
  // event handler
  const handleButtonClick = () => {
    navigate(config.pages.booking.path);
  };

  // parameters
  const hasParams = !_.isEmpty(params);
  const showButton = !!code && !!productKey;
  const btnSize = hasParams && params.size ? params.size : 'medium';
  const btnVariant = hasParams && params.variant ? params.variant : 'gradient';
  const btnColor = hasParams && params.color ? params.color : 'info';
  const btnLabel = hasParams && params.label ? params.label : 'Booking';

  return (
    <StyledBox>
      {showButton && (
        <StyledButton
          onClick={handleButtonClick}
          variant={btnVariant}
          size={btnSize}
          color={btnColor}
        >
          {btnLabel}
        </StyledButton>
      )}
    </StyledBox>
  );
}

BookingButton.propTypes = {
  code: PropTypes.string.isRequired,
  productKey: PropTypes.string.isRequired,
  params: PropTypes.instanceOf(Object),
};

export default BookingButton;

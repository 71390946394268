import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-theme-material-ui';
import Icon from '@mui/material/Icon';
import Chip from '@mui/material/Chip';

// appsolut base components
import StyledTypography from '../../components/controls/StyledTypography';
import StyledBadge from '../../components/controls/StyledBadge';
import StyledBox from '../../components/controls/StyledBox';

function Tag({ tag, type, color, withLink, ...rest }) {
  const textVariant = 'body2';
  const textColor = color === 'light' ? 'white' : 'secondary';
  const textWeight = 'regular';
  const buttonVariant = 'body2';
  const buttonColor = tag.color || color || 'text';
  const buttonWeight = 'regular';
  const chipVariant = 'filled';
  const chipColor = tag.color || color || 'default';
  const badgeVariant = 'contained';
  const badgeColor = tag.color || color || 'info';

  let tagLink = '#';
  if (withLink) {
    tagLink = tag && tag.path ? tag.path : tagLink;
  }

  return (
    <Fragment>
      {type === 'text' && (
        <Fragment>
          {withLink ? (
            <StyledTypography
              key={tag.id}
              component={Link}
              to={tagLink}
              variant={textVariant}
              color={textColor}
              fontWeight={textWeight}
            >
              {tag.label}
            </StyledTypography>
          ) : (
            <StyledTypography
              key={tag.id}
              variant={textVariant}
              color={textColor}
              fontWeight={textWeight}
            >
              {tag.label}
            </StyledTypography>
          )}
        </Fragment>
      )}
      {type === 'button' && (
        <Fragment>
          {withLink ? (
            <StyledTypography
              key={tag.id}
              component={Link}
              to={tagLink}
              lineHeight={1}
              variant={buttonVariant}
              fontWeight={buttonWeight}
              color={buttonColor}
              sx={{ display: 'flex', alignItems: 'center' }}
              {...rest}
            >
              {tag.icon && (
                <StyledBox mr={1}>
                  <Icon fontSize="medium" color={buttonColor}>
                    {tag.icon}
                  </Icon>
                </StyledBox>
              )}
              {tag.label}
            </StyledTypography>
          ) : (
            <StyledTypography
              key={tag.id}
              lineHeight={1}
              variant={buttonVariant}
              fontWeight={buttonWeight}
              color={buttonColor}
              sx={{ display: 'flex', alignItems: 'center' }}
              {...rest}
            >
              {tag.icon && (
                <StyledBox mr={1}>
                  <Icon fontSize="medium" color={buttonColor}>
                    {tag.icon}
                  </Icon>
                </StyledBox>
              )}
              {tag.label}
            </StyledTypography>
          )}
        </Fragment>
      )}
      {type === 'chip' && (
        <Fragment>
          {withLink ? (
            <Chip
              key={tag.id}
              component={Link}
              to={tagLink}
              label={tag.label}
              variant={chipVariant}
              color={chipColor}
              clickable
              {...rest}
            />
          ) : (
            <Chip
              key={tag.id}
              label={tag.label}
              variant={chipVariant}
              color={chipColor}
              {...rest}
            />
          )}
        </Fragment>
      )}
      {type === 'badge' && (
        <Fragment>
          {withLink ? (
            <StyledBadge
              key={tag.id}
              component={Link}
              to={tagLink}
              badgeContent={tag.label}
              variant={badgeVariant}
              color={badgeColor}
              container
              {...rest}
            />
          ) : (
            <StyledBadge
              key={tag.id}
              badgeContent={tag.label}
              variant={badgeVariant}
              color={badgeColor}
              container
              {...rest}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

Tag.defaultProps = {
  type: 'text',
  color: 'default',
  withLink: false,
};

Tag.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    path: PropTypes.string,
    icon: PropTypes.string,
    color: PropTypes.string,
  }).isRequired,
  type: PropTypes.oneOf(['text', 'button', 'chip', 'badge']),
  color: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  withLink: PropTypes.bool,
};

export default Tag;

import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import ProductTag from '../elements/Tag';

function ProductTags({ header, tags, type, color, withLink, space, ...rest }) {
  const spacing = space ? { xs: 2, lg: 3, xl: 6 } : { xs: 1, lg: 2, xl: 3 };

  return (
    <StyledBox component="section" pb={{ xs: 2, md: 2 }}>
      <Container>
        <Grid container>
          {header && (
            <Grid item xs={12} lg={9} mx="auto">
              <StyledBox mb={1}>
                <StyledTypography variant="caption">{header}</StyledTypography>
              </StyledBox>
            </Grid>
          )}
          {tags && (
            <Grid item xs={12} lg={9} mx="auto">
              <Stack
                direction="row"
                flexWrap="wrap"
                justifyContent="flex-start"
                spacing={spacing}
                mb={3}
              >
                {tags.map((tag) => (
                  <ProductTag
                    key={tag.id}
                    tag={tag}
                    type={type}
                    color={color}
                    withLink={withLink}
                    {...rest}
                  />
                ))}
              </Stack>
            </Grid>
          )}
        </Grid>
      </Container>
    </StyledBox>
  );
}

ProductTags.defaultProps = {
  type: 'text',
  color: 'info',
  withLink: false,
  space: false,
};

ProductTags.propTypes = {
  header: PropTypes.string,
  tags: PropTypes.array.isRequired,
  type: PropTypes.oneOf(['text', 'button', 'chip', 'badge']),
  color: PropTypes.oneOf([
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'light',
    'dark',
  ]),
  withLink: PropTypes.bool,
  space: PropTypes.bool,
};

export default ProductTags;

import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import StyledHtmlViewer from '../../components/controls/StyledHtmlViewer';
import StyledProductPrice from '../../components/controls/StyledProductPrice';
import { formatPrice } from '../../utils/format-price';
import * as config from '../../../config/site';

function ProductVariantCard({ product = {}, variant = {} }) {
  const productHeader = product && product.alias ? product.alias : product.title;
  const meta = productHeader || 'Kategorie';
  const title = variant.title || '--';
  const description = variant.description || '';

  const currencyCode = config.catalog.currency.code || 'EUR';
  const priceFormated = formatPrice(currencyCode, variant.price);
  const priceInfo = variant.info || '';

  const isSpecialPrice = variant.isspecial || false;
  let specialPriceFormated = '';
  if (isSpecialPrice) specialPriceFormated = formatPrice(currencyCode, variant.specialprice);

  const priceProps = {
    price: priceFormated,
    ismin: false,
    info: priceInfo,
    isspecial: isSpecialPrice,
    specialprice: specialPriceFormated,
  };

  return (
    <StyledBox mt={3}>
      <Card>
        <StyledBox p={3} mt={1}>
          {meta && (
            <StyledTypography
              display="block"
              variant="button"
              color="text"
              fontWeight="regular"
              mb={0.75}
            >
              {meta}
            </StyledTypography>
          )}
          <StyledTypography display="inline" variant="h5" fontWeight="bold">
            {title}
          </StyledTypography>
          <StyledBox mt={1} mb={3}>
            <StyledHtmlViewer>
              <StyledTypography
                component="div"
                variant="body2"
                color="text"
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </StyledHtmlViewer>
          </StyledBox>
          <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-end">
            <StyledProductPrice price={priceProps} />
          </Stack>
        </StyledBox>
      </Card>
    </StyledBox>
  );
}

ProductVariantCard.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  variant: PropTypes.instanceOf(Object).isRequired,
};

export default ProductVariantCard;

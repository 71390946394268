import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import ProductCard from '../elements/ProductCard';

function Products({ products = [], header, catalogType, bookingPage = false }) {
  const hasItems = products && products.length > 0;

  return (
    <Fragment>
      {hasItems && (
        <>
          {bookingPage && (
            <StyledBox component="section" pt={{ xs: 0 }} pb={{ xs: 3, md: 6 }}>
              <Container>
                <Grid container item px={{ xs: 0, lg: 6 }}>
                  {header && (
                    <StyledBox ml={0} mb={4}>
                      <StyledTypography variant="h3">{header}</StyledTypography>
                    </StyledBox>
                  )}
                  <Grid container spacing={3}>
                    {products.map((product) => (
                      <Grid item key={product.id} xs={12} md={6} xl={4}>
                        <StyledBox key={product.id}>
                          <ProductCard
                            key={product.id}
                            product={product}
                            catalogType={catalogType}
                            fromApi={true}
                          />
                        </StyledBox>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Container>
            </StyledBox>
          )}
          {!bookingPage && (
            <StyledBox component="section" pt={{ xs: 3, md: 6 }}>
              <Container sx={{ maxWidth: '100% !important' }}>
                <Grid container item xs={12} lg={9} mx="auto">
                  {header && (
                    <StyledBox ml={0} mb={4}>
                      <StyledTypography variant="h3">{header}</StyledTypography>
                    </StyledBox>
                  )}
                  <Grid container spacing={3}>
                    {products.map((product) => (
                      <Grid item key={product.id} xs={12} md={6} xl={4}>
                        <StyledBox key={product.id}>
                          <ProductCard
                            key={product.id}
                            product={product}
                            catalogType={catalogType}
                          />
                        </StyledBox>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Container>
            </StyledBox>
          )}
        </>
      )}
    </Fragment>
  );
}

Products.propTypes = {
  products: PropTypes.array,
  header: PropTypes.string,
  catalogType: PropTypes.string.isRequired,
  bookingPage: PropTypes.bool,
};

export default Products;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import ProductVariantCard from '../elements/ProductVariantCard';

function ProductVariants({ header, product = {}, variants = [] }) {
  const hasVariants = variants && variants.length > 0;

  return (
    <Fragment>
      {hasVariants && (
        <StyledBox component="section" py={{ xs: 1, md: 2 }}>
          <Container>
            <Grid container>
              {header && (
                <Grid item xs={12} lg={9} mx="auto">
                  <StyledBox mb={2}>
                    <StyledTypography variant="caption">{header}</StyledTypography>
                  </StyledBox>
                </Grid>
              )}
              <Grid item xs={12} lg={9} mx="auto">
                <Grid container spacing={3}>
                  {variants.map((variant) => (
                    <Grid item key={variant.id} xs={12} md={6} xl={6}>
                      <StyledBox>
                        <ProductVariantCard key={variant.id} product={product} variant={variant} />
                      </StyledBox>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </StyledBox>
      )}
    </Fragment>
  );
}

ProductVariants.propTypes = {
  header: PropTypes.string,
  product: PropTypes.instanceOf(Object),
  variants: PropTypes.array,
};

export default ProductVariants;

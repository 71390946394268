import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import BookingCard from '../../components/shapes/Cards/BookingCards/PriceBookingCard';
import { formatPrice } from '../../utils/format-price';
import * as pathUtils from '../../utils/path';
import * as nodesUtils from '../../utils/nodes';
import * as config from '../../../config/site';

const emptyImage =
  'https://res.cloudinary.com/appsolut365/image/upload/v1654611297/appsolut365/webportal/default/klein_bfyrwm.jpg';

function ProductCard({ product, catalogType, fromApi }) {
  // static query
  const data = useStaticQuery(graphql`
    query {
      catalogs: allDatoCmsCatalog {
        edges {
          node {
            id
            title
            originalId
          }
        }
      }
      siteSetting: datoCmsSiteSetting {
        catalog {
          id
          title
          originalId
        }
        catalogAlt {
          id
          title
          originalId
        }
      }
    }
  `);
  const { catalogs, siteSetting } = data;
  const allCatalogs = catalogs.edges;

  // parameter handling
  // const node = product && product.node ? product.node : {};
  const node = product;
  const { title, alias, image, featured, teaser } = node;
  const { venues, destinations } = node;
  const { price, ismin, priceAlias } = node;

  const productTitle = alias ? alias : title;
  const currencyCode = config.catalog.currency.code || 'EUR';
  const priceFormated = formatPrice(currencyCode, price);
  const pageCode = title ? title : '';
  const catalogSettings = nodesUtils.getSiteCatalogs(siteSetting, allCatalogs);
  const pageBasePath = config.catalog.item.slug;
  const pagePath = pathUtils.createPagePathOfMultiCatalog(
    catalogType,
    pageBasePath,
    pageCode,
    catalogSettings
  );

  const hasImage = !_.isEmpty(image);
  let imageSrc = emptyImage;
  if (fromApi) {
    if (hasImage && image.responsiveImage) imageSrc = image.responsiveImage.src;
  } else {
    if (hasImage) imageSrc = getSrc(image.gatsbyImageData);
  }
  const venueItems = nodesUtils.getLabelsFromNodes(venues);
  const destinationItems = nodesUtils.getLabelsFromNodes(destinations);
  const categoryItems = venueItems.concat(destinationItems);

  const priceProps = {
    price: priceFormated,
    ismin: ismin,
    alias: priceAlias,
  };
  const actionProps = {
    type: 'internal',
    route: pagePath,
    color: 'dark',
    label: 'Wählen',
  };

  return (
    <StyledBox mt={3}>
      <BookingCard
        image={imageSrc}
        title={productTitle}
        featured={featured}
        description={teaser}
        price={priceProps}
        categories={categoryItems}
        action={actionProps}
      />
    </StyledBox>
  );
}

ProductCard.defaultProps = {
  fromApi: false,
};

ProductCard.propTypes = {
  product: PropTypes.instanceOf(Object).isRequired,
  fromApi: PropTypes.bool,
  catalogType: PropTypes.string.isRequired,
};

export default ProductCard;

export const query = graphql`
  fragment ProductCard on DatoCmsItem {
    id
    originalId
    producttype
    title
    alias
    coverHeader
    booking
    featured
    weight
    teaser
    price
    ismin
    priceAlias
    noCatalog
    venues {
      ...VenueCard
    }
    destinations {
      ...DestinationCard
    }
    patches {
      ...PatchCard
    }
  }

  fragment ProductWithImageCard on DatoCmsItem {
    ...ProductCard
    image {
      gatsbyImageData(width: 450)
      alt
      basename
    }
  }

  fragment CatalogCard on DatoCmsCatalog {
    id
    originalId
    title
    teaser
    content
  }

  fragment CatalogWithImageCard on DatoCmsCatalog {
    ...CatalogCard
    image {
      gatsbyImageData(width: 450)
      alt
      basename
    }
  }

  fragment PatchCard on DatoCmsPatch {
    id
    originalId
    title
    icon
    color
    description
  }

  fragment QualityCard on DatoCmsQuality {
    id
    originalId
    title
    icon
    color
    description
  }

  fragment ServiceCard on DatoCmsService {
    id
    originalId
    title
    icon
    color
    description
  }

  fragment PropertyCard on DatoCmsProperty {
    id
    originalId
    title
    icon
    color
    description
  }

  fragment PersonBlockCard on DatoCmsPersonBlock {
    id
    originalId
    title
    information
    description
  }

  fragment PersonBlockWithImageCard on DatoCmsPersonBlock {
    ...PersonBlockCard
    image {
      gatsbyImageData(width: 450)
      alt
      basename
    }
  }

  fragment PriceBlockCard on DatoCmsPriceBlock {
    id
    originalId
    title
    info
    price
    isspecial
    specialprice
    description
  }
`;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getSrc } from 'gatsby-plugin-image';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Stack from "@mui/material/Stack";

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import StyledHtmlViewer from '../../components/controls/StyledHtmlViewer';

const emptyImage =
  'https://res.cloudinary.com/appsolut365/image/upload/v1654611297/appsolut365/webportal/default/klein_bfyrwm.jpg';

function Address({ address }) {
  const hasAddress = !_.isEmpty(address);
  const image = address && address.image ? address.image : {};
  const hasImage = !_.isEmpty(image);
  const imageSrc = hasImage ? getSrc(image.gatsbyImageData) : emptyImage;
  const addressTitle = address && address.title ? address.title : 'Address';
  const addressContact = address && address.contact ? address.contact : '';
  const addressStreet = address && address.street ? address.street : '';
  const addressStreet2 = address && address.street2 ? address.street2 : '';
  const addressCountry = address && address.country ? address.country : '';
  const addressZipCode = address && address.zipCode ? address.zipCode : '';
  const addressOrt = address && address.ort ? address.ort : '';
  const addressOrtStr = addressZipCode ? `${addressZipCode} ${addressOrt}` : addressOrt;
  const addressArrival = address && address.arrival ? address.arrival : '';

  return (
    <Fragment>
      {hasAddress && (
        <StyledBox component="section" position="relative" pt={14} pb={{ xs: 1, md: 2 }}>
          <Container sx={{ maxWidth: '100% !important' }}>
            <Grid container item xs={12} lg={9} mx="auto">
              <StyledBox variant="gradient" bgColor="dark" borderRadius="lg" width="100%" p={6}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6} xl={4} sx={{ position: 'relative' }}>
                    <StyledBox
                      component="img"
                      src={imageSrc}
                      alt="image"
                      borderRadius="md"
                      maxWidth="300px"
                      width="100%"
                      position="relative"
                      mt={-10}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={8} sx={{ position: 'relative' }}>
                    <StyledTypography variant="h3" color="white">
                      {addressTitle}
                    </StyledTypography>
                  </Grid>
                  {addressContact && (
                    <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                      <StyledBox mb={0}>
                        <StyledTypography variant="caption" color="white">
                          Kontakt
                        </StyledTypography>
                        <StyledHtmlViewer>
                          <StyledTypography
                            component="div"
                            variant="body2"
                            color="white"
                            dangerouslySetInnerHTML={{ __html: addressContact }}
                          />
                        </StyledHtmlViewer>
                      </StyledBox>
                    </Grid>
                  )}
                  <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                    <StyledBox mb={0}>
                      <StyledTypography variant="caption" color="white">
                        Adresse
                      </StyledTypography>
                      <StyledTypography variant="body2" color="white" sx={{ fontSize: '1.125rem' }}>
                        {addressStreet}
                      </StyledTypography>
                      <StyledTypography variant="body2" color="white" sx={{ fontSize: '1.125rem' }}>
                        {addressStreet2}
                      </StyledTypography>
                      <StyledTypography variant="body2" color="white" sx={{ fontSize: '1.125rem' }}>
                        {addressOrtStr}
                      </StyledTypography>
                      <StyledTypography variant="body2" color="white" sx={{ fontSize: '1.125rem' }}>
                        {addressCountry}
                      </StyledTypography>
                    </StyledBox>
                  </Grid>
                  {addressArrival && (
                    <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                      <StyledBox mb={0}>
                        <StyledTypography variant="caption" color="white">
                          Anreiseinformation
                        </StyledTypography>
                        <StyledHtmlViewer>
                          <StyledTypography
                            component="div"
                            variant="body2"
                            color="white"
                            dangerouslySetInnerHTML={{ __html: addressArrival }}
                          />
                        </StyledHtmlViewer>
                      </StyledBox>
                    </Grid>
                  )}
                </Grid>
              </StyledBox>
            </Grid>
          </Container>
        </StyledBox>
      )}
    </Fragment>
  );
}

Address.propTypes = {
  address: PropTypes.instanceOf(Object).isRequired,
};

export default Address;

import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import StyledProductPrice from '../../components/controls/StyledProductPrice';
import BookingButton from '../elements/BookingButton';

function ProductBooking({ header, price, bookingCode, productKey, actionParams }) {
  return (
    <StyledBox component="section" pb={{ xs: 3, md: 3 }}>
      <Container>
        <Grid container>
          {header && (
            <Grid item xs={12} lg={9} mx="auto">
              <StyledBox mb={1}>
                <StyledTypography variant="caption">{header}</StyledTypography>
              </StyledBox>
            </Grid>
          )}
          <Grid item xs={12} lg={9} mx="auto">
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="flex-end">
              <StyledProductPrice price={price} />
              {bookingCode && (
                <BookingButton code={bookingCode} productKey={productKey} params={actionParams} />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </StyledBox>
  );
}

ProductBooking.propTypes = {
  header: PropTypes.string,
  price: PropTypes.instanceOf(Object),
  bookingCode: PropTypes.string.isRequired,
  productKey: PropTypes.string.isRequired,
  actionParams: PropTypes.instanceOf(Object),
};

export default ProductBooking;
